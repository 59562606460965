import './aboutMe.css'
import image from './vlad.jpg'
import skillsList from './skillsList'
import { LinkedIn, GitHub } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function Skills(props){
    return(
        <p className='info--skills'>{props.item.skill}</p>
    )
}



function AboutMe(){
    function openSocial(socialNetwork){
        if (socialNetwork === "LinkedIn"){
            window.open("https://www.linkedin.com/in/vlad-kargaltsev-b128861a4/", '_blank')
        } else {
            if (socialNetwork === "GitHub"){
                window.open("https://github.com/VladSpadey", '_blank')
            } else {
                
            }
        }
    }

    const skills = skillsList.map(item => {
        return(
            <Skills
                key={item.id}
                item={item}
            />
        );
    })

    

    return(
        <div className="Me">
            <div className='aboutMe'>
            <img src={image} id="me--img" alt="me"></img>
                <div className="info">
                    <section className="section--info">
                        <h2 id="info--header">Vlad Kargaltsev</h2>   
                        <p id="info--prof">- fullstack developer / vancouver</p>
                    </section>
                    <section className='section--icons'>  
                            <IconButton onClick={() => openSocial("LinkedIn")}>
                                <LinkedIn id="LinkedIn" sx={{ 
                                    fontSize: 43,
                                    fill:"#777786",
                                    "&:hover": { fill: "#6C22A6" } }}/>
                            </IconButton>       
                            <IconButton onClick={() => openSocial("GitHub")}>
                                <GitHub sx={{ 
                                    fill:"#777786",
                                    fontSize: 40,
                                    "&:hover": { fill: "#6C22A6" }
                                    }}/>
                            </IconButton>
                    </section>
                    <p id="info--job">Looking for a Co-Op/Intern position</p>
                    <a id="info--resume" href='resume'>Show Resume</a>
                    <h3 id="info--skills">Skills</h3>
                    <section className="section--skills">
                        {skills}
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;