import './emailForm.css'

function EmailForm(){
    function sendEmail(){
        window.open(`mailto:v.kargaltsev@gmail.com`);
    }
    return(
        <div className='end--body'>
            <div className='email-body'>
                <h2>Have a question?</h2>
                <div className="contact--div">
                    <input className='contact--button' type="button" value="Contact Me" onClick={sendEmail}/>
                </div>
            </div>
        </div>
    )
}

export default EmailForm