import '../components/projects/projectPage.css'
import videoHomePage from '../images/whattoplay-home.gif'
import videoComments from '../images/whattoplay-comments.gif'
import videoGamePage from '../images/gamePage.mp4'
import videoGameAuth from '../images/gameAuth.mp4'

const WhatToPlay = () => {
    return(
        <div className="p--p">
            <div className="p--p--container">
                <div className="p--p--info">
                    <h1>WhatToPlay</h1>
                    <a href='https://github.com/VladSpadey/what-to-play'>GitHub Link</a>
                    <p>Dates of Development: January, 2024 - Current</p>
                    <h4>Tools and Technologies:</h4>
                    <p>ReactJS, JavaScript, MongoDB, NodeJS, ExpressJS</p>
                    <p>Firebase, Redux, Swiper.js</p>
                    <h4>About the Project:</h4>
                    <ul>
                        <li>Web application for suggesting video games to the user. 
                        Think Netflix but for games.</li>
                        <li>Every game consist of Steam's data and Metacritic data combined, which I get from using an automated Scraper made with Puppeteer and Cheerio libraries.</li>
                    </ul>
                        <h4>Planning to Add:</h4>
                        <ul>
                            <li>When registering, the user will be prompted to choose which games they like, their favorite genres and their platforms,
                            which will be used for suggesting games later on</li>
                            <li>Scraping of YouTube to get game reviews</li>
                            <li>The user is also able to add any game to their list and tag it with "Completed", "Dropped", "Plan to Play" etc.., and rate the games on a scale out of 10.</li>
                        </ul>
                </div>
                <div className="p--p--details">
                    <h2>Videos and Images:</h2>
                    <h4>(The website is still in Development)</h4>
                    <div className='p--container'>
                        <h3>Home Page:</h3>
                        <p>Featured games are being shown at the very top</p>
                        <img src={videoHomePage} alt='WhatToPlay - Home Page Gif'/>
                    </div>

                    <div className='p--container'>
                        <h3>Game Page:</h3>
                        <p>Editing a game is only available for users with Admin privelege</p>
                        <p>Comment section is still in the works</p>
                        <p>Similar games are fetched and sorted by the similarity of tags</p>
                        <video src={videoGamePage} controls/>
                    </div>

                    <div className='p--container'>
                        <h3>Authentication:</h3>
                        <p>Authentication is done with Firebase and the user's session data is stored in Redux </p>
                        <video src={videoGameAuth} controls/>
                    </div>

                    <div className='p--container'>
                        <h3>Comments:</h3>
                        <p>Reddit-style comments, with upvote and downvote system.</p>
                        <p>Supports nested replies</p>
                        <img src={videoComments} alt='WhatToPlay - Comments Gif'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatToPlay;