import projectData from "./projectData"
import './project.css'
import imgLifeCounter from '../../images/gamelifecounter.png'
import imgDungeonClicker from '../../images/dungeonClicker.png'
import imgCalorieGuide from '../../images/calorieGuide.png'
import imgPhoneMarket from '../../images/phoneMarket.png'
import imgWhatToPlay from '../../images/whatToPlay.png'

function ProjectCards(props){
    function openLink(){
        if (props.item.link === undefined) {
          } else {
            window.location.href = props.item.link;
          }
    }
    let img = null;
    switch (props.item.title) {
        case "WhatToPlay":
            img = imgWhatToPlay;
            break;
        case "CalorieGuide":
            img = imgCalorieGuide;
            break;
        case "GameLifeCounter":
            img = imgLifeCounter;
            break;
        case "Phone Market":
            img = imgPhoneMarket;
            break;
        case "Dungeon Clicker":
            img = imgDungeonClicker;
            break;
        default:
            img = null;
    }

    return(
        <div className="card" onClick={openLink}>
            <img src={img} alt="project cover" />
            <p className="card--tech">{props.item.language}</p>
            <div className="card--desc--container">
                <h3 className="card--title">{props.item.title}</h3> 
                <p className="card--desc">{props.item.description}</p>
            </div>

        </div>
    )
}

function Projects(){
    const cards = projectData.map(item => {
        return(
            <ProjectCards
                key={item.id}
                item={item}
            />
        );
    })


    return(
        <div className="ProjectTile">
            <h2 id="project--header">My Projects</h2>
            <section className="cards--section">
                {cards}
            </section>
        </div>
    )
}

export default Projects