import '../components/projects/projectPage.css'
import auth from '../images/calorieGuideAuth.webm'
import weight from '../images/calorieGuideWeight.webm'
import intake from '../images/calorieGuideIntake.webm'

const CalorieGuide = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '../images/calorie-guide.apk';
        link.download = 'CalorieGuide.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return(
        <div className="p--p">
            <div className="p--p--container">
                <div className="p--p--info">
                    <h1>CalorieGuide</h1>
                    <a href='https://github.com/VladSpadey/CalorieGuide'>GitHub Link</a> <br/> <br/>
                    <a href='' onClick={handleDownload}>Download APK</a>
                    <p>Dates of Development: October, 2023 - November, 2023</p>
                    <h4>Tools and Technologies:</h4>
                    <p>Java, Android Studio, Firestore</p>
                    <p>Firebase, AnyChart</p>
                    <h4>About the Project:</h4>
                    <ul>
                        <li>An android app that allows users to keep track of their weight, calorie intake, and calculates their BMI and BMR. Uses charts to make information easier to absorb for the user. </li>
                        <li>Calculates how much weight the user needs to lose to achieve a healthy weight, and how many calories should be taken per day to achieve a healthy weight in the rate chosen by the user.</li>
                        <li>The user can use EdamamAPI (Food and Nutrients API) to search for the food they ate and keep track of the calories they have eaten per day.</li>
                    </ul>
                </div>
                <div className="p--p--details">
                    <h2>Videos and Images:</h2>
                    <div className='p--container--phone'>
                        <h3>Authorization:</h3>
                        <video src={auth} controls/>
                    </div>

                    <div className='p--container--phone'>
                        <h3>Adding Calorie Intake:</h3>
                        <video src={intake} controls/>
                    </div>

                    <div className='p--container--phone'>
                        <h3>Adding Weight:</h3>
                        <video src={weight} controls/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalorieGuide;