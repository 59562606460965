import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom'
import Home from './pages/Home';
import RootLayout from './layouts/RootLayout'
import Resume from './pages/Resume';
import WhatToPlay from './pages/WhatToPlay';
import CalorieGuide from './pages/CalorieGuide';
import PhoneMarket from './pages/PhoneMarket';

//import './App.css';
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />

      <Route path='/resume'>
        <Route index={true} element={<Resume />}></Route>
      </Route>

      <Route path='/what-to-play'>
        <Route index={true} element={<WhatToPlay />}></Route>
      </Route>

      <Route path='/calorie-guide'>
        <Route index={true} element={<CalorieGuide />}></Route>
      </Route>

      <Route path='/phone-market'>
        <Route index={true} element={<PhoneMarket />}></Route>
      </Route>

    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
