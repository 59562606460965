import '../components/projects/projectPage.css'
import videoHomePage from '../images/phoneMarket.gif'

const PhoneMarket = () => {
    return(
        <div className="p--p">
            <div className="p--p--container">
                <div className="p--p--info">
                    <h1>PhoneMarket</h1>
                    <a href='https://github.com/VladSpadey/market'>GitHub Link</a> <br/> <br/>
                    <p>Dates of Development: June, 2023 - August, 2023</p>
                    <h4>Tools and Technologies:</h4>
                    <p>JavaScript, MongoDB, React, NodeJS, ExpressJS</p>
                    <h4>About the Project:</h4>
                    <ul>
                        <li>This project was made with the intention of learning the MERN-stack</li>
                        <li>A website-parody of a phone selling store.</li>
                        <li>Allows the user to scroll through phones that were added to the database, check their details, sort, and filter through them, add them to cart and checkout..</li>
                    </ul>
                </div>
                <div className="p--p--details">
                    <h2>Videos and Images:</h2>
                    <div className='p--container--phone'>
                        <h3>Demo Gif:</h3>
                        <img src={videoHomePage} controls alt='Demo Gif of Phone Market'/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PhoneMarket;