const Array = [
    {
        id : 1,
        skill : 'Python'
    },
    {
        id : 2,
        skill : 'C#'
    },
    {
        id : 3,
        skill : 'Java'
    },
    {
        id : 4,
        skill : 'HTML5'
    },
    {
        id : 5,
        skill : 'CSS3'
    },
    {
        id : 6,
        skill : 'JavaScript'
    },
    {
        id : 7,
        skill : 'ReactJS'
    },
    {
        id : 8,
        skill : 'NodeJS'
    },
    {
        id : 9,
        skill : 'Next'
    },
    {
        id : 10,
        skill : 'Express'
    },
    {
        id : 11,
        skill : 'Android Studio'
    },
    {
        id : 12,
        skill : 'Spring Boot'
    },
    {
        id : 13,
        skill : 'MySQL'
    },
    {
        id : 14,
        skill : 'MongoDB'
    },
    {
        id : 15,
        skill : 'Firestore'
    },
]

export default Array;