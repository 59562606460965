import resumePDF from '../images/resume-coop-2024.pdf'

const Resume = () => {
    return(
        <div className="page--resume">
            <iframe src={resumePDF} title='Vlad-K-resume'></iframe>
        </div>
    );
}

export default Resume