import EmailForm from '../components/contactMe/emailForm';
import Projects from '../components/projects/projects'
import AboutMe from '../components/aboutMe/AboutMe';

const Home = () => {
    return(
        <div>
            <AboutMe/>
            <Projects/>
            <EmailForm/>
        </div>
    );
}

export default Home
