 const Array = [
    {
        id : 1,
        title : "WhatToPlay",
        image : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fevergreendm.com%2Fwp-content%2Fuploads%2F2021%2F03%2Fwebsite-project-checklist.jpg&f=1&nofb=1&ipt=2c2693132bb0debe4eefcd9a55881d4446c0d8c0c8e20cac341af92e49a38dd9&ipo=images",
        language : "MERN-stack, Firebase, Redux",
        description : "A web application for suggesting video games to users. Think Netflix but for games. Using Scrapers to get Video Game's data from multiple websites.",
        link : "/what-to-play",
        status: 'In Progress'
    },
    {
        id : 2,
        title : "CalorieGuide",
        image : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fevergreendm.com%2Fwp-content%2Fuploads%2F2021%2F03%2Fwebsite-project-checklist.jpg&f=1&nofb=1&ipt=2c2693132bb0debe4eefcd9a55881d4446c0d8c0c8e20cac341af92e49a38dd9&ipo=images",
        language : "Java, Android Studio",
        description : "An android app that allows users to keep track of their weight, calorie intake, and calculates their BMI and BMR. Uses charts to make information easier to absorb for the user.",
        link : "/calorie-guide"
    },
    {
        id : 3,
        title : "GameLifeCounter",
        image : "../../images/gamelifecounter.png",
        language : "ReactJS",
        description : "A Life counter website, that allows the user to keep count of their life in Trading Card Games with up to 6 players. ",
        link : "https://gamelifecounter.com/"
    },
    {
        id : 4,
        title : "Phone Market",
        image : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fevergreendm.com%2Fwp-content%2Fuploads%2F2021%2F03%2Fwebsite-project-checklist.jpg&f=1&nofb=1&ipt=2c2693132bb0debe4eefcd9a55881d4446c0d8c0c8e20cac341af92e49a38dd9&ipo=images",
        language : "MERN-stack",
        description : "A website-parody of a phone selling store. Allows the user to scroll through phone selection, check their details, sort, and filter through them, add them to cart and checkout.",
        link : "/phone-market"
    },
    {
        id : 5,
        title : "Dungeon Clicker",
        image : "https://img.itch.zone/aW1nLzYwMzc5MTMucG5n/original/EkTQsS.png",
        language : "Python, Pygame",
        description : "A Game made with Pygame. In this endless dungeon roguelike, you need to kill your enemy and advance further in the dungeon. After each round, you may choose an upgrade.",
        link : "https://spadeyus.itch.io/dungeon-clicker"
    },

]

export default Array;